@import '../../styles/customMediaQueries.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.content {
  margin: 0;
}

.sectionTitle .title {
  font-size: 28px !important;
}

@media (max-width: 768px) {
  .sectionTitle .title {
    font-size: 24px !important;
  }
}

.textMuted {
  color: #8492a6 !important;
}
