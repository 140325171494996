.submitButton {
  max-width: 250px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 50px;
}

.row div {
  flex-grow: 1;
}
