.title {
  composes: h2 from global;
  margin-top: 0;
  font-size: 28px !important;
}

.heroButton {
  width: 40%;
  height: 50px;
  @media (max-width: 425px) {
    width: 100%;
  }
}

.textMuted {
  color: #8492a6 !important;
}

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
}
